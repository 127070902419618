import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatCardModule } from '@angular/material/card';
import { MatToolbarModule } from '@angular/material/toolbar';

@Component({
  selector: 'app-content-wrapper',
  standalone: true,
  imports: [CommonModule, MatCardModule, MatToolbarModule],
  templateUrl: './content-wrapper.component.html',
  styleUrl: './content-wrapper.component.scss',
})
export class ContentWrapperComponent {
  @Input() title: string = '';
  @Input() padding: boolean = true;
  @Input() headerColor?: string;
}
