<mat-toolbar [ngStyle]="headerColor ? { 'background-color': headerColor, 'color': '#ffffff'} : {}"
    [ngClass]="['header', !padding ? 'no-padding' : '']" color="primary">
    {{title}}
    <span class="spacer"></span>
    <ng-content select="[toolbar]"></ng-content>
</mat-toolbar>

<mat-card class="content" [ngClass]="['header', !padding ? 'no-padding' : '']">
    <mat-card-content>
        <ng-content select="[content]"></ng-content>
    </mat-card-content>
</mat-card>
